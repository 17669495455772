import React from 'react'
import SignComp from '../components/UserComponents/Login/SignComp'
const Signup = () => {
  return (
    <div>
      <SignComp/>
    </div>
  )
}

export default Signup
